//Libs
import React from "react";
import { headShake } from "react-animations";

//Components
import Picker from "emoji-picker-react";
import Checkbox from "./SuperChat/Checkbox";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { getHumanDate } from "Utils/Datetools";

//Styles
import styled, { keyframes } from "styled-components";
import { Radio } from "antd";
import FacebookSelector from "./FacebookReactions/FacebookSelector";

//MainContainer
export const MainContainer = styled.div`
  #chart {
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    svg text {
      fill: white;
    }
  }
  /* 
  //change color text label
  svg text {
    fill: black;
  } */
  display: grid;
  grid-template-rows: auto 170px;
  height: 100%;
`;

//MainContainer Poll
export const MainContainerPoll = styled.div`
  padding: 10px 0px;
  color: #616764;
  // animation: 2s ${keyframes`${headShake}`};
  background: linear-gradient(
    to right,
    #141414,
    #232222
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: auto;
  height: 550px;
  overflow-x: hidden;
  .ant-checkbox-group-item {
    display: block;
    margin: 8px;
  }
  .ant-checkbox {
    margin-right: 5px;
  }
`;

//fixed message
export const FixedMessage = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  //background: linear-gradient(to right, #8e0e00, #1f1c18); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-size: 20px;
  height: 60px;
  z-index: 10;
`;

export const CardPoll = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 9px;
`;
export const PlusAddOptions = styled(PlusCircleTwoTone)`
  font-size: 30px;
  cursor: pointer;
  margin-left: 5.5%;
  margin-top: 1%;
`;
export const ThanksAnswer = styled.div`
  text-align: center;
  color: #47ab5c;
`;
export const PlusCreatePoll = styled(PlusCircleOutlined)`
  font-size: 20px;
  cursor: pointer;
  margin-left: 5.5%;
  position: relative;
  bottom: 3px;
`;

// export const ClosePoll = styled(CloseCircleTwoTone)`
//   font-size: 20px;
//   cursor: pointer;
//   margin: 5px;
//   text-align: left;
// `;
export const DeleteAnswerOption = styled(DeleteOutlined)`
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  float: right;
  position: relative;
  bottom: 5px;
`;
export const SwitchContainer = styled.div`
  position: absolute;
  bottom: 0px;
  margin-left: 70%;
`;

export const PollTitle = styled.h5`
  color: #616764;
  text-align: center;
  margin-top: 10px;
`;

export const PollSubtitle = styled.h6`
  color: #616764;
  margin-left: 5%;
`;

//MessageContainer
export const MessageContainer = styled.div`
  height: ${(props) => (props.mobileView ? "auto" : "calc(100vh - 170px)")};
  margin-top: 0px;
  overflow-x: auto;
  padding: 20px 20px 0px 20px;
  background-color: #000000a1;
  width: ${(props) => (props.mobileView ? "100vw" : "30vw")};
`;

//ActionContainer
export const ActionContainer = styled.div`
  /* Write styles here... */
`;

//ActionSubcontainer
export const ActionSubcontainer = styled.div`
  /* Write styles here... */
`;

//MessageCard
const MCard = styled.div`
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
`;

//userName of message
const Username = styled.span`
  color: white;
  margin-bottom: 0px;
  font-weight: bold;
`;
//date of message
const Date = styled.span`
  color: #fff;
  margin-left: 5px;
  font-size: 12px;
`;
//Content of message
const Content = styled.p`
  color: white;
`;

export const MessageCard = ({
  idx,
  message,
  fullScreenChat,
  currentUserId,
}) => {
  const { userId, createdAt, body, isQuestion, user } = message;
  const { name } = user;
  return (
    <MCard
      id={`message_${idx}`}
      key={idx}
      style={{
        border: isQuestion && "1px solid #e40a14",
        background:
          currentUserId === userId
            ? "linear-gradient(183deg ,#3261e4 ,#264290,#0d193c)"
            : "linear-gradient( 183deg ,#784fa5 ,#633d8c,#3f1869)",
      }}
      fullScreenChat={fullScreenChat}
    >
      <div>
        <Username>@{name}</Username>
        <Date>{getHumanDate(createdAt)}</Date>
      </div>
      <Content>{body}</Content>
    </MCard>
  );
};

//PollCard
const PCard = styled.div`
  margin-bottom: 20px;
`;
const SendAnswersButton = styled.button``;
//TODO crear pollQuestions
//TODO crear pollReview

export const PollCard = ({
  key,
  poll,
  onAnswerPoll,
  checkAnswer,
  userVoteYet,
}) => {
  //Este de aca es lo que se encarga de enviar el answer
  const [value, setValue] = React.useState({});
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <PCard key={key}>
      {userVoteYet ? (
        <div style={{ padding: "20px" }} className="card mt-3">
          <ThanksAnswer>
            <h4 className="ml-2">Gracias por su respuesta!</h4>
          </ThanksAnswer>
        </div>
      ) : (
        <div style={{ padding: "20px" }} className="card mt-3">
          <h4 style={{ textAlign: "center" }}>{poll.question}</h4>

          {poll.isMultiAnswer ? (
            <Checkbox
              answerOptions={poll.options}
              onAnswerPoll={onAnswerPoll}
              checkAnswer={checkAnswer}
              isMultiAnswer={poll.isMultiAnswer}
            />
          ) : (
            poll.options.map((option, idx) => (
              <Radio.Group key={idx} onChange={onChange} value={value}>
                <Radio style={radioStyle} value={option} className="h5">
                  {option.name}
                </Radio>
              </Radio.Group>
            ))
          )}

          {poll.isMultiAnswer ? null : (
            <div style={{ marginTop: "20px" }} className="row">
              <div className="col-sm">
                <SendAnswersButton
                  onClick={() => {
                    onAnswerPoll(value, poll.isMultiAnswer, true); // true porque es local
                    checkAnswer();
                  }}
                  className="btn btn-success col-sm"
                >
                  Enviar
                </SendAnswersButton>
              </div>
            </div>
          )}
        </div>
      )}
    </PCard>
  );
};

//Emoticon Picker
// const Picker2 = new Picker();
// export const EmoticonPicker = styled(() => <Picker2 />)`
/* Write styles here... */
//`;

//Reaction Picker
export const ReactionPicker = styled(FacebookSelector)`
  width: 50px;
`;

export const PollButton = styled.button`
  /* Write styles here... */
`;

export const ChatMessageInputContainer = styled.div`
  padding: 20px;
  background-color: #000000a1;
  width: ${(props) => (props.mobileView ? "100vw" : "30vw")};
`;

export const InputCheckBoxMessage = styled.input`
  cursor: pointer;
  color: white;
  font-size: large;
`;

export const SpanQuestionMessage = styled.h5`
  color: white;
  font-size: small;
  display: inline;
  margin-left: 5px;
  position: relative;
  bottom: 2px;
`;
export const EmoticonIcons = styled.div`
  cursor: pointer;
  font-size: x-large;
  position: relative;
  right: 45px;
  width: 50px;
`;
//MessageInput
export const MessageInput = styled.input`
  width: 100%;
  height: 45px;
  padding-right: 48px;
  padding-left: 10px;
`;

export const EmojiPicker = styled(Picker)`
  aside.emoji-picker-react {
    margin-top: 50%;
    background-color: black;
  }
`;
//This is the button add in Add Poll question
export const AddQuestionButton = styled.button`
  display: flex;
  position: absolute;
  right: 19px;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 25px;
  margin-top: 18px;
  background-color: transparent;
  outline: 2px solid transparent;
  outline-offset: 2px;
  &:hover {
    color: #633d8c;
  }
  @media (max-width: 450px) {
    font-size: 20px;
    height: 39px;
    widows: 44px;
    margin-top: 27px;
  }
`;
