//Libs
import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
//Components
import Spinner from "Components/Spinner/Spinner";
import FormInput from "../../Components/FormInput/FormInput";
import { CustomButton } from "Components";
//Services
import { resetPassword } from "Services/UserService";
//Config File
import { ROUTES } from "Config/config";
//redux
import { connect } from "react-redux";
import { selectTokenPassWord } from "Redux/User/user-selectors";
import { logout } from "Redux/User/user-actions";

class ResetPassword extends React.Component {
  constructor(props) {
    super();
    this.companyId = props.match.params.email;
    this.email = props.match.params.code;

    this.state = {
      loading: false,
      password: "",
      confirmPassword: "",
      passwordDontMatch: false,
      passwordInvalid: false,
      resetPasswordsuccessful: null,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { password, confirmPassword } = this.state;
    if (password.length < 8) {
      this.setState({ passwordInvalid: true });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({ passwordDontMatch: true, passwordInvalid: false });
      return;
    }

    try {
      this.setState({ loading: true, passwordDontMatch: null });
      const resetPasswordsuccessful = await resetPassword(
        this.props.token,
        this.companyId,
        password
      );
      this.setState({ resetPasswordsuccessful, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    const {
      loading,
      password,
      confirmPassword,
      passwordDontMatch,
      passwordInvalid,
      resetPasswordsuccessful,
    } = this.state;

    if (resetPasswordsuccessful) {
      this.props.logout();
    }

    if (resetPasswordsuccessful) return <Redirect to={ROUTES.SIGNIN} />;

    return (
      <div className="overflow-x-hidden">
        {loading && <Spinner />}
        <div className="bg-cover bg-center bg-sign-in-background min-h-full md:min-w-b-120 w-full h-auto fixed top-0 left-0" />
        <div className="w-full flex justify-center">
          <div className="bg-forg-pass-back-img mt-1.0 h-auto w-96 absolute top-0 p-10 max-md:h-screen">
            <h2 className="text-center ml-0 md:m-3 mt-6 mb-6 text-primary-title font-bold">
              Nueva Contraseña
            </h2>
            <form action="POST" onSubmit={this.handleSubmit}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <h5 className="ml-3 md:ml-5 md:mt-8 md:mb-6 ">
                  Cuenta {this.email}
                </h5>
              </div>
              <FormInput
                name="password"
                type="password"
                maxLength={50}
                minLength={8}
                value={password}
                onChange={this.handleChange}
                label="Contraseña"
                required
              />
              <FormInput
                name="confirmPassword"
                type="password"
                maxLength={50}
                minLength={8}
                value={confirmPassword}
                onChange={this.handleChange}
                label="Confirmar Contraseña"
                required
              />

              <div className="w-full flex justify-center">
                <div className="inline-flex flex-col justify-center mt-3 text-center ">
                  <CustomButton type="submit">Cambiar</CustomButton>
                </div>
              </div>
            </form>

            {resetPasswordsuccessful === false && (
              <div className="w-full flex justify-center">
                <span className="text-primary-forgot-error">
                  Hubo un problema. Por favor vuelva a intentarlo
                </span>
              </div>
            )}

            {passwordDontMatch && (
              <div className="w-full flex justify-center">
                <span className="text-primary-forgot-error">
                  Las contraseñas no coinciden
                </span>
              </div>
            )}

            {passwordInvalid && (
              <div className="w-full flex justify-center">
                <span className="text-primary-forgot-error">
                  Debe tener una longitud mínima de 8 caracteres
                </span>
              </div>
            )}

            <div className="w-full flex justify-center">
              <span className="text-gray-400 text-center mr-1">
                ¿Ya tiene una contraseña?
              </span>
              <Link to={ROUTES.SIGNIN} className="text-white no-underline">
                Ingresar.
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: selectTokenPassWord(state),
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(ResetPassword)));
