const ghostStyle = (body) => ({
  num: [0, 1],
  v: [1, 8], //Speed
  alpha: [0.1, 0.9], //Transparency
  scale: [0.05, 0.09], //Size
  body, //Icon
  position: "center",
  g: 5, //Gravity
});

const ballonStyle = (body) => ({
  num: [0, 1],
  rps: 0.1,
  radius: [1, 1],
  life: [1, 1],
  v: [2, 2], //Speed
  tha: [-1, 50], //Direction
  alpha: [0.1, 0.5], //Transparency
  scale: [0.05, 0.09], //Size
  body, //Icon
  position: "all",
  //color: ["random", "#ff0000"],
  cross: "dead",
  random: 1,
});

export const styleType = {
  GHOST: "GHOST",
  BALLON: "BALLON",
};

const configParticles = (body, style) => {
  switch (style) {
    //GHOST
    case styleType.GHOST:
      return ghostStyle(body);
    //BALLON
    case styleType.BALLON:
      return ballonStyle(body);
    default:
      return null;
  }
};

export default configParticles;